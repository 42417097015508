import React, { useState, useEffect, Fragment } from "react";
import Button from "../../../../../components/Buttons/button";
import DataScoreCard from "../../../../../components/data-score-card/DataScoreCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  companyPublishReports,
  companyPublishMonthlyReports,
  getManageServicesDataScore,
  selectCompanyDashboard,
  UploadCompanyIcon,
  UpdateOrgCostVisibility,
  getOrgCostVisibility,
  updateCompanyCurrency,
} from "../../../../../redux/slices/companyDashboardSlice";
import {
  getUserDetails,
  selectUser,
} from "../../../../../redux/slices/userSlice";
import { motion, AnimatePresence } from "framer-motion";
import BasicModal from "../../../../models/models";
import TimezonePicker from "react-timezone-select";
import OutsideClickHandler from "react-outside-click-handler";
// import CurrencyCodes from 'currency-codes';
import { Combobox, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

const OrgSetting = ({ setModel }) => {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [id, setId] = useState("");
  const [monthlyReport, setMonthlyReport] = useState(false);
  const [visibleCost, setVisibleCost] = useState(false);
  const [startDay, setStartDay] = useState();
  const [startTime, setStartTime] = useState();
  const [dayDropdown, setDayDropdown] = useState(false);
  const [autoNotification, setAutoNotification] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [query, setQuery] = useState("");
  const [timezone, setTimezone] = useState();
  const dispatch = useDispatch();
  const State = useSelector(selectCompanyDashboard);
  const {
    manageServicesData,
    orgCostVisibility,
    publishCompanyReport,
    UserServiceData,
    publishCompanyReportTime,
    company_logo,
    companyCurrency,
  } = State;

  const UserState = useSelector(selectUser);
  const { userDetails } = UserState;
  const reportTime = manageServicesData.monthly_report_time;
  const reportDate = manageServicesData.monthly_report_date;
  const reportDay = manageServicesData.monthly_report_day;
  const reportTimezone = manageServicesData.monthly_report_timezone;
  const service_ids = manageServicesData.service_id_without_USD;
  const LastGeneration = manageServicesData.last_report_time;
  const monthlyReportData = manageServicesData.monthly_report_publishing;
  const autoNotifyData = manageServicesData.auto_notification;
  const company_image = userDetails?.user?.company_image_url;
  const company_currency = userDetails?.currency_symbol;
  // const currencies = CurrencyCodes.codes()
  // currencies are supported by the European Central Bank (ECB)
  const currencies = [
    "USD", "EUR", "GBP", "JPY", "AUD", "CAD", "CHF", "SEK", "NOK", "DKK",
    "PLN", "CZK", "HUF", "BGN", "RON", "TRY", "ZAR", "HKD", "SGD", "CNY",
    "KRW", "MXN", "BRL", "INR", "IDR", "MYR", "PHP", "THB", "ISK"
  ];

  useEffect(() => {
    if (reportTime) {
      const currentDate = new Date();
      const [hours, minutes] = reportTime.split(":");
      const newDate = new Date(currentDate.setHours(hours, minutes, 0, 0));
      setStartTime(newDate);
    }

    if (reportDay) {
      // const newDate = new Date(reportDay)
      setStartDay(reportDay);
    }

    if (reportTimezone) {
      setTimezone(reportTimezone);
    }

    if (monthlyReportData) {
      setMonthlyReport(monthlyReportData);
    }

    if (autoNotifyData) {
      setAutoNotification(autoNotifyData);
    }
  }, [reportDate, monthlyReportData, autoNotifyData]);

  useEffect(() => {
    if (orgCostVisibility) {
      setVisibleCost(orgCostVisibility?.cost);
    }
  }, [orgCostVisibility?.cost]);

  useEffect(() => {
    dispatch(getManageServicesDataScore());
  }, [dispatch, publishCompanyReport, publishCompanyReportTime]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch, company_logo, companyCurrency]);

  useEffect(() =>{
    if(company_currency){
      setSelectedCurrency(company_currency);
    }
  }, [company_currency])

  useEffect(() => {
    dispatch(getOrgCostVisibility());
  }, [visibleCost]);


  const handlePublishReport = () => {
    dispatch(companyPublishReports());
  };

  const HandleMonthlyReport = (e) => {
    setMonthlyReport(e.target.checked);
    handleCompanyReportUpdate("monthlyReport", e.target.checked)
  };

  const HandleAutoNotification = (e) => {
    setAutoNotification(e.target.checked);
    handleCompanyReportUpdate("autoNotification", e.target.checked);
  };

  const handleTimeChange = (date) => {
    if (date) {
      setStartTime(date);
      handleCompanyReportUpdate("startTime", date);
    } else {
      setStartTime(null);
    }
  };

  const handleDayChange = (day) => {
    setStartDay(day);
    setDayDropdown(false);
    handleCompanyReportUpdate("startDay", day);
  };

  const handleTimezone = (selectedTimezone) => {
    const timezoneValue = selectedTimezone?.value;

    if (timezoneValue) {
      setTimezone(timezoneValue);
      handleCompanyReportUpdate("timezone", timezoneValue);
    } else {
      console.error("Invalid timezone selected");
    }
  };


  const handleCompanyReportUpdate = (field, value) => {
    if (field === 'startTime') {
      value = `${value.getHours()}:${value.getMinutes()}`
    }

    const updatedSettings = {
      monthlyReport,
      autoNotification,
      startDay,
      startTime: startTime ? `${startTime.getHours()}:${startTime.getMinutes()}` : null,
      timezone,
      [field]: value, // Dynamically update the field
    };

    dispatch(companyPublishMonthlyReports(updatedSettings));
  };


  const toggleDropdown = () => {
    setDayDropdown(!dayDropdown);
  };

  const companyIconeUpload = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      dispatch(UploadCompanyIcon(file));
    }
  };

  const handleDataScore = () => {
    setState(53);
    setPopup(true);
  };


  const handleCostVisibility = (e) => {
    const checkedValue = e.target.checked;
    setVisibleCost(checkedValue);
    dispatch(UpdateOrgCostVisibility(checkedValue));
  };

  const daysArray = Array.from({ length: 28 }, (_, i) => i + 1);

  const filteredCurrencies =
  query === ""
    ? currencies
    : currencies.filter((currency) =>
        currency.toLowerCase().includes(query.toLowerCase())
      );

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    dispatch(updateCompanyCurrency({ operating_currency: currency }));
  };


  return (
    <div className="pt-[24px] pb-8 px-8 flex flex-col items-baseline">
      <div className=" flex flex-col w-full pb-[24px] border-b-[1px] border-[#434A5D]">
        <div className="flex items-center text-primary font-[700] gap-[16px] !text-[24px] text-left">
          {company_image && (
            <img src={company_image} alt="logo" className="companyImage" />
          )}
          {userDetails?.user?.company_name} settings
        </div>
        <div>
          <label className="text-secondary flex cursor-pointer items-center gap-1 cursor-pointer pt-[10px] hover:text-[#ffe766]">
            <input
              hidden
              type="file"
              id="company_icon"
              name="company"
              accept=".png, .jpg, .jpeg"
              onChange={(e) => companyIconeUpload(e)}
            />
            Click here to upload your company icon (png, jpg).
          </label>
        </div>
      </div>
      <div className="max-h-[595px] overflow-y-scroll scrollbar-hide">
        <div className="border-b-[1px] border-[#434A5D] py-[24px] w-full px-[5px]">
          <div className="flex justify-between items-center">
            <div className="text-primary font-[700] text-[16px] text-left">
              Reports
            </div>
            <div className="flex gap-4 items-center">
              <div className="flex flex-col items-end">
                <div className="text-secondary">Last generation:</div>
                <div className="text-secondary">
                  {LastGeneration
                    ? `${new Date(LastGeneration).toLocaleDateString("en-GB")}`
                    : ""}
                </div>
              </div>
              <div className="">
                <Button
                  onClick={() => handlePublishReport()}
                  className="btnHover bg-gray-700 btnText"
                  outlined
                >
                  Publish reports now
                </Button>
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-4 pt-[15px]">
              <input
                type="checkbox"
                checked={monthlyReport}
                onChange={HandleMonthlyReport}
                className="border border-[#A5AEBC] rounded-[4px] text-white !bg-transparent mt-[7px] cursor-pointer"
              />
              <div className="flex flex-col flex-start items-baseline">
                <div className="text-primary !font-[500] !text-[14px] leading-none text-left">
                  Automatically publish all reports monthly
                </div>
                <div className="text-secondary font-[400] !text-[14px] !text-[#A5AEBC] leading-none text-left ">
                  Thryft will automatically publish new reports for each period
                  using available service data. The relevant reports will be
                  visible to all users.
                </div>
              </div>
            </div>
            <AnimatePresence>
              {monthlyReport && (
                <motion.div
                  classNmae="flex flex-col"
                  initial={{ opacity: 0, height: 0 }}
                  animate={
                    monthlyReport
                      ? { opacity: 1, height: "auto" }
                      : { opacity: 0, height: 0 }
                  }
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                  // style={{ overflow: 'hidden'}}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <div className="flex items-center pl-[33px] gap-4 py-[15px]">
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        setDayDropdown(false);
                      }}
                    >
                      <div className="orgSetting-day">
                        <div className="flex flex-col MonthPickerWrap relative">
                          <div className="absolute top-[10px] left-[16px]">
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 3V1M13 3V5M13 3H8.5M1 9V18C1 18.5304 1.21071 19.0391 1.58579 19.4142C1.96086 19.7893 2.46957 20 3 20H17C17.5304 20 18.0391 19.7893 18.4142 19.4142C18.7893 19.0391 19 18.5304 19 18V9M1 9H19M1 9V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H5M19 9V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H16.5M5 1V5"
                                stroke="#A5AEBC"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <input
                            readOnly
                            value={startDay ? `${startDay}` : ""}
                            placeholder="Select day"
                            className="border-[1px] bg-[#363D50] border-[#596073] p-2 cursor-pointer rounded-[16px] text-center flex items-center justify-center"
                            onClick={toggleDropdown}
                          />
                          {dayDropdown && (
                            <div className="absolute top-[55px] w-[200px] px-3 py-2 left-[10px] z-10 bg-[#293043] rounded-[16px] border-[1px] border-[#596073] max-h-[250px] overflow-y-scroll shadow-md scrollbar-hide">
                              {daysArray.map((day) => (
                                <div
                                  key={day}
                                  className="text-center py-2 cursor-pointer hover:bg-[#2684FF]"
                                  onClick={() => handleDayChange(day)}
                                >
                                  {day}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </OutsideClickHandler>
                    <div className="text-secondary"> at </div>
                    <div>
                      <div className="orgSetting-time">
                        <div className="flex flex-col MonthPickerWrap relative">
                          <div className="absolute top-[12px] left-[16px]">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 22.5C9.9233 22.5 7.89323 21.8842 6.16652 20.7304C4.4398 19.5767 3.09399 17.9368 2.29927 16.0182C1.50455 14.0996 1.29661 11.9884 1.70176 9.95156C2.1069 7.91476 3.10693 6.04383 4.57538 4.57538C6.04383 3.10693 7.91476 2.1069 9.95156 1.70176C11.9884 1.29661 14.0996 1.50455 16.0182 2.29927C17.9368 3.09399 19.5767 4.4398 20.7304 6.16652C21.8842 7.89323 22.5 9.9233 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 3C10.22 3 8.47992 3.52785 6.99987 4.51678C5.51983 5.50571 4.36628 6.91132 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61306 20.0518 7.32387 18.364 5.63604C16.6761 3.94822 14.387 3 12 3Z"
                                fill="#A5AEBC"
                              />
                              <path
                                d="M15.4425 16.5L11.25 12.3075V5.25H12.75V11.685L16.5 15.4425L15.4425 16.5Z"
                                fill="#A5AEBC"
                              />
                            </svg>
                          </div>
                          <DatePicker
                            selected={startTime}
                            onChange={(date) => handleTimeChange(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            placeholderText="Select time"
                            timeIntervals={60}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            className=""
                            // popperClassName="!w-full !b-[#293043] text-[#fffff]"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="timeZoneWrapper">
                      <TimezonePicker
                        value={timezone}
                        onChange={handleTimezone}
                        inputProps={{
                          placeholder: "Select Timezone...",
                          name: "timezone",
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 pt-[15px] pl-[33px]">
                    <input
                      type="checkbox"
                      checked={autoNotification}
                      onChange={HandleAutoNotification}
                      className="border border-[#A5AEBC] rounded-[4px] text-white !bg-transparent mt-[7px] cursor-pointer"
                    />
                    <div className="flex flex-col flex-start items-baseline">
                      <div className="text-primary !font-[500] !text-[14px] leading-none text-left">
                        Automatically notify service manager owners
                      </div>
                      <div className="text-secondary font-[400] !text-[14px] !text-[#A5AEBC] leading-none text-left ">
                        Thryft will send an email to service owners 10 days
                        before the selected generation date and time, reminding
                        them to upload service usage data.
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div
              className="pt-[20px] cursor-pointer"
              onClick={() => handleDataScore()}
            >
              <DataScoreCard
                manageServicesData={manageServicesData}
                service_ids={service_ids}
              />
            </div>
          </div>
        </div>
        <div className="border-b-[1px] border-[#434A5D] py-[24px] w-full  px-[5px]">
          <div className="text-primary font-[700] text-[16px] text-left">
            Rules
          </div>
          <div className="flex gap-4 pt-[15px]">
            <input
              type="checkbox"
              checked={visibleCost}
              onChange={handleCostVisibility}
              className="border border-[#A5AEBC] rounded-[4px] text-white !bg-transparent cursor-pointer"
            />
            <div className="flex flex-col flex-start items-baseline">
              <div className="text-primary !font-[500] !text-[14px] leading-none text-left">
                Total cost visability
              </div>
              <div className="text-secondary font-[400] !text-[14px] !text-[#A5AEBC] leading-none text-left ">
                Employees can view cost totals when accessing reports from their
                employee dashboards.
              </div>
            </div>
          </div>
        </div>
        {userDetails?.user?.role == "admin" &&
          <div className="border-b-[1px] border-[#434A5D]">
            <div className="w-1/2 mt-2 py-5">
              <div className="flex items-center text-primary font-[700] gap-[16px] !text-[16px] text-left pb-4">
                Update company currency
              </div>
              <Combobox value={selectedCurrency} onChange={handleCurrencyChange}>
                <div className="relative mt-1">
                  <Combobox.Input
                    className="relative custom_text bg-[#363D50] border border-[#596073] rounded-[12px] focus:ring-[#596073] focus:border-[#596073] block w-full px-[16px] py-[12px] text-left text-white"
                    displayValue={(currency) => currency}
                    onChange={(event) => setQuery(event.target.value)}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon className="h-5 w-5 text-secondary" />
                  </Combobox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Combobox.Options className="absolute mt-1 max-h-40 w-full overflow-auto rounded-md bg-[#363D50] py-1 text-white shadow-lg scrollbar-hide z-10">
                      {filteredCurrencies.length === 0 && query !== "" ? (
                        <div className="cursor-default select-none py-2 px-4 text-secondary">
                          No results found
                        </div>
                      ) : (
                        filteredCurrencies.map((currency, idx) => (
                          <Combobox.Option
                            key={idx}
                            className={({ active }) =>
                              `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                active ? "bg-[#596073] text-white" : "text-secondary"
                              }`
                            }
                            value={currency}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium text-white" : "font-normal"
                                  }`}
                                >
                                  {currency}
                                </span>
                                {selected && (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                    <CheckIcon
                                      className={`h-5 w-5 ${
                                        active ? "text-white" : "text-secondary"
                                      }`}
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </Combobox>
            </div>
          </div>
        }
      </div>

      {popup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          // manageServicesData={manageServicesData}
          setModel={setModel}
          id={id}
          setId={setId}
        />
      )}
    </div>
  );
};

export default OrgSetting;
