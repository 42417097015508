import React, {Fragment, useEffect, useRef, useState } from "react";
import Checkbox from "../../Checkbox/Checkbox";
import Select from "../../Select/select";
import Button from "../../Buttons/button";
import TextField from "../../TextField/TextField";
import {updateContractData, createContractData, selectCompanyDashboard } from '../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../assets/icons/error-icon.svg'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Listbox, Transition } from '@headlessui/react';
import { motion, AnimatePresence } from "framer-motion"

import DragDrop from "./components/DragDrop";
import StartDate from "./components/StartDate";
import EndDate from "./components/EndDate";

const MonthList = [
    { id: 1, name: '1 month'},
    { id: 2, name: '2 month'},
    { id: 3, name: '3 month'},
    { id: 4, name: '4 month'},
    { id: 5, name: '5 month'},
  ]

function ManageContractModel({setPopup,serviceId}){

  const dispatch = useDispatch();
  const [contract_file, setFile] = useState("");
  const [start_date, setStart_date] = useState()
  const [end_date, setEnd_date] = useState()
  const [dropdown, setDropdown] = useState(false)
  const [selectedType, setSelectedType] = useState(MonthList[0]);
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPersentage, setUploadPersentage] = useState(0)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [renewalNotice, setRenewalNotice] = useState(false)
  const [toggleIcon, setToggleIcon] = useState(false)
  const [toggleEndIcon, setToggleEndIcon] = useState(false)
  const [error, setError] = useState({
    start_date: false,
    end_date: false,
    contract_file: false,
    selectedType: false
  })

  const addMonths = (date, months) => {
    let temp = new Date(date)
    temp.setMonth(temp.getMonth() + months)
    if (temp.getDate() != date.getDate()) temp.setDate(0)
    return temp
  }

  const subtractMonths = (date, months) => {
    let temp = new Date(date);
    temp.setMonth(temp.getMonth() - months);
    if (temp.getDate() !== new Date(date).getDate()) {
      temp.setDate(0);
    }
    return temp;
  };

  useEffect(()=>{
    if (contract_file){
      setIsUploading(false)
    }
  },[contract_file])

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const newErrors = {
      start_date: false,
      end_date: false,
      contract_file: false,
    };

    if (!start_date) {
      newErrors.start_date = true;
    }

    if (!end_date) {
      newErrors.end_date = true;
    }

    if (!contract_file) {
      newErrors.contract_file = true;
    }

    setError(newErrors);

    if (Object.values(newErrors).some(err => err)) {
      return;
    }

    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("contract_file", contract_file);
    formData.append("service_id", serviceId);
    if (renewalNotice) {
      const renewalDate = subtractMonths(end_date, selectedType.id);
      formData.append("renewal_date", renewalDate.toString());
    }
    dispatch(createContractData(formData));
    setPopup(false)
  };

  const handleContractCheckbox = (e) =>{
    setDropdown(!dropdown)
    setRenewalNotice(e.target.checked)
  }

  return (
    <div className="transform rounded-[24px] bg-[#363D50] transition-all flex">
      <div className="flex pt-[16px] pr-[24px] pb-[24px] pl-[40px] lg:w-[720px] ">
        <div className="flex flex-col SetupServiceModel">
          <div className="flex justify-between items-center border-b-[1px] pt-[20px] pb-[20px] mb-[30px] border-[#434A5D]">
            <div className="text-left">
              <div className="text-primary !text-[24px] !leading-[36px]">Upload new service contract</div>
            </div>
            <svg
              className="cursor-pointer"
              onClick={() => {
                setPopup(false);
              }}
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                fill="white"
              />
            </svg>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px] addServiceForm">
              <DragDrop
                setFile={setFile}
                contract_file={contract_file}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                uploadPersentage={uploadPersentage}
                setUploadPersentage={setUploadPersentage}
                uploadSuccess={uploadSuccess}
                setUploadSuccess={setUploadSuccess}
                error={error}
                setError={setError}
                setPopup={setPopup}
              />
              <div className="dateRangeBox">
                <div className="flex flex-col MonthPickerWrap relative">
                  <div className={`absolute top-[46px] right-[15px] ${ toggleIcon ? 'rotate-180' : null }`}>
                    <ChevronDownIcon
                      className="h-5 w-5 text-secondary"
                      aria-hidden="true"
                    />
                  </div>
                  <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
                    Start date
                  </label>
                  <StartDate
                    start_date={start_date}
                    setStart_date={setStart_date}
                    placeholder="Select"
                    error={error}
                    setError={setError}
                    setToggleIcon={setToggleIcon}
                  />
                </div>

                <div className="flex flex-col MonthPickerWrap relative">
                  <div className={`absolute top-[46px] right-[15px] ${ toggleEndIcon ? 'rotate-180' : null }`}>
                    <ChevronDownIcon
                      className="h-5 w-5 text-secondary"
                      aria-hidden="true"
                    />
                  </div>
                  <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
                    End date
                  </label>
                  <EndDate
                    end_date={end_date}
                    setEnd_date={setEnd_date}
                    placeholder="Select"
                    error={error}
                    setError={setError}
                    setToggleEndIcon={setToggleEndIcon}
                  />
                </div>
              </div>
              <div>
                <div className=" flex !flex-start flex-col border border-[#596073] rounded-[16px] focus:ring-[#596073] focus:border-[#596073]">
                  <label className="block cursor-pointer w-full px-[16px] py-[12px] custom_text">
                    <div className="flex items-center gap-2">
                      <input type='checkbox' onClick={handleContractCheckbox} className="border-[1px] border-[#A5AEBC] rounded-[4px] text-white !bg-transparent" />
                      <div className="text-secondary leading-[20px] text-left">This contract will auto-renew</div>
                    </div>
                  </label>
                  <div className="!flex !flex-start !flex-col px-[16px]">
                    <AnimatePresence>
                      { dropdown && 
                        <motion.div
                          className="border-t-[1px] border-[#434A5D] pt-[20px]"
                          initial={{ opacity: 0, height: 0 }}
                          animate={ dropdown ? { opacity: 1, height: 'auto' }: { opacity: 0, height: 0 }}
                          transition={{ duration: 0.2, ease: 'easeInOut' }}
                          // style={{ overflow: 'hidden'}}
                          exit={{ opacity: 0, height: 0 }}
                        >
                          <div className="text-primary !font-semibold text-left">
                            Non-renewal notice
                          </div>
                          <div className="text-secondary leading-[20px] text-left">Choose when to send a non-renewal email to the service owner</div>

                          <div className="selectTypeWrap pb-6 pt-1">
                            <Listbox value={selectedType} onChange={setSelectedType}>
                              <div className="relative mt-1">
                                <Listbox.Button className="custom_text relative bg-[#363D50] border border-[#596073] rounded-[12px] focus:ring-[#596073] focus:border-[#596073] block w-[100%] px-[16px] py-[12px] text-left">
                                  <span className="">{selectedType.name}</span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon
                                      className="h-5 w-5 text-secondary"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute mt-1 max-h-[130px] scrollbar-hide w-[100%] overflow-auto rounded-md bg-[#363D50] py-1 text-[#596073] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
                                    {MonthList?.map((perm) => (
                                      <Listbox.Option
                                        key={perm.id}
                                        className={({ active }) =>
                                          `custom_text relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-[#596073] text-secondary' : 'text-secondary'
                                          }`
                                        }
                                        value={perm}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <span
                                              className={`flex flex-start${
                                                selected ? ' font-medium' : ' font-normal'
                                              }`}
                                            >
                                              {perm?.name}
                                            </span>
                                            {selected ? (
                                              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          </div>
                        </motion.div>
                      }
                    </AnimatePresence>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-end items-center gap-[12px] pt-[20px]">
              <Button
                onClick={() => setPopup(false)}
                className="btnHover bg-gray-700 btnText"
                outlined
              >
                Cancel
              </Button>
              <button
                type="submit"
                // onClick={()=> handleServiceContract()}
                className="btnHover bg-[#FFD74A] hover:bg-[#FFE766] focus:bg-[#EAC400] !text-[#242B3E] pt-[14px] pl-[16px] pb-[14px] pr-[16px] w-[fit-content] group transform border-[1px] border-[rgba(255,255,255,0)] h-[fit-content] rounded-[16px] !text-[14px] text-primary flex gap-[8px]"
              >
                Upload
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ManageContractModel;
